import { Link } from 'gatsby'
import React from 'react'
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaBars,
  FaLinkedin,
} from 'react-icons/fa'
import './header.scss'

const Header = () => {
  const toggle = () => {
    document.querySelector('.header-top-links').classList.toggle('open')
  }
  const closeNav = () => {
    document.querySelector('.header-top-links').classList.remove('open')
  }

  return (
    <>
      <section className="subpage-header">
        <div className="subpage-header-top">
          <Link
            className="home-link"
            to="/"
            state={{ home: true }}
            onClick={closeNav}
          >
            <img
              className="plclogo"
              src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto,f_auto/v1604155754/plctexas/plclogo.png"
              alt="PLC Texas"
            />
          </Link>

          <div className="toggle" onClick={toggle} role="navigation">
            <FaBars />
          </div>
          <div className="header-top-links">
            <Link
              to="/"
              state={{ home: true }}
              className="header-link"
              onClick={closeNav}
            >
              Home
            </Link>

            <Link
              to="/about"
              state={{ home: false }}
              className="header-link"
              onClick={closeNav}
            >
              About
            </Link>

            <Link
              className="header-link"
              to="/services"
              state={{ home: false }}
              onClick={closeNav}
            >
              Services
            </Link>
            <Link
              className="header-link"
              to="/clients"
              state={{ home: false }}
              onClick={closeNav}
            >
              Clients
            </Link>
            <Link
              className="header-link"
              to="/resources"
              state={{ home: false }}
              onClick={closeNav}
            >
              Resources
            </Link>
            <div className="header-social-wrapper">
              <a
                className="header-social"
                href="https://www.facebook.com/plctexas/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare aria-label="Facebook" alt="Facebook" />
              </a>
              <a
                className="header-social"
                href="https://twitter.com/PLC_Texas"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitterSquare aria-label="Twitter" alt="Twitter" />
              </a>
              <a
                className="header-social"
                href="https://www.linkedin.com/company/premier-legislative-consulting/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin aria-label="LinkedIn" alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Header
