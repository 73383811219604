import React from 'react'
// import { Link } from "gatsby"
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaLinkedin,
  FaEnvelope,
  FaPhone,
} from 'react-icons/fa'

const Footer = () => (
  <>
    <footer>
      <div className="footer-contact">
        <div className="footer-left">
          <div className="footer-address">
            <p>PLC Texas</p>
            <p>1309 Concho Street</p>
            <p>Austin, TX 78702-1111</p>
          </div>
        </div>
        <div className="footer-center">
          <div className="footer-social">
            <a
              href="https://www.facebook.com/plctexas/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare
                className="footer-social-icon"
                aria-label="Facebook"
                alt="Facebook"
              />
            </a>
            <a
              href="https://twitter.com/PLC_Texas"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitterSquare
                className="footer-social-icon"
                aria-label="Twitter"
                alt="Twitter"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/premier-legislative-consulting/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin
                className="footer-social-icon"
                aria-label="LinkedIn"
                alt="LinkedIn"
              />
            </a>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-john">
            <p>John Hubbard</p>
            <p>
              <a href="tel:1-512-653-6428">
                <FaPhone className="footer-phone-icon" />
                512-653-6428
              </a>
            </p>
            <p>
              <a href="mailto:john@plctexas.com">
                <FaEnvelope className="footer-email-icon" />
                john@plctexas.com
              </a>
            </p>
          </div>
          <div className="footer-ian">
            <p>Ian Randolph</p>
            <p>
              <a href="tel:1-512-632-4833">
                <FaPhone className="footer-phone-icon" />
                512-632-4833
              </a>
            </p>
            <p>
              <a href="mailto:ian@plctexas.com">
                <FaEnvelope className="footer-email-icon" />
                ian@plctexas.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="footer-legal">
        <p>Copyright 2020 PLC Texas</p>
        <p>
          A{' '}
          <a
            href="https://danbuda.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dan Buda
          </a>{' '}
          Joint
        </p>
      </div>
    </footer>
  </>
)

export default Footer
