import React, { useState, useEffect } from 'react'
import './testimonials.scss'

const quotes = [
  {
    text:
      'Premier Legislative Consulting has represented the Texas Telephone Association for over five years — a time of significant turmoil in the Texas telecommunications industry. They are relentlessly hard workers, and their legislative and political expertise has not only resulted in significant gains for TTA as an association but helped establish us as thought-leaders in telecommunications at the legislature. We are so pleased with their work they were awarded our Associate Members of the Year in 2019!',
    author: 'Rusty Moore',
    org: 'President, Texas Telephone Association',
  },
  {
    text:
      'Ian and John are well-known for their ability to quickly grasp public policy issues and develop successful legislative solutions. I knew I could trust them to not only understand a complicated issue but to effectively develop a message easily understood by busy legislators and their staff. Their tenacity, strong work ethic, and deep relationships throughout the Capitol ensure their lobby clients are well served.',
    author: 'Kathy Hutto',
    org: 'Former Lobbyist at Jackson Walker LLP',
  },
  {
    text:
      'The guys at Premier Legislative Consulting are a joy to work with. They can do it all: make the intricacies of the legislative process understandable to all, design effective grassroots advocacy programs, and help ensure that state agencies don’t develop overly burdensome rules. I would recommend them to anyone needing help in the realm of government affairs.',
    author: 'Mary Loftin, CNM',
    org: 'Former President, Coalition for Nurses in Advanced Practice',
  },
  {
    text:
      'Premier Legislative Consulting not only has the reputation and credibility for success at the Texas Capitol, they fully understand how the political and legislative universes interact. Add to that their team-centered approach, determination, and strategic outlook and you can understand why we wanted them working for us.',
    author: 'Scott Stringer',
    org: 'Director, Legislative and Regulatory Affairs, CenturyLink',
  },
  {
    text:
      'The work that Premier Legislative Consulting has done for the benefit of our students residing in Rural School Districts has been outstanding over the past few years. Ian and John have been quite instrumental in the success TARS has experienced at the State Capitol!',
    author: 'Mike Lee',
    org: 'Executive Director, Texas Association of Rural Schools',
  },
]

const Testimonials = () => {
  const initialQuote = Math.floor(Math.random() * quotes.length)
  const [currentQuote, setCurrentQuote] = useState(0)
  const [quoteText, setQuoteText] = useState(quotes[initialQuote].text)
  const [quoteAuthor, setQuoteAuthor] = useState(quotes[initialQuote].author)
  const [quoteOrg, setQuoteOrg] = useState(quotes[initialQuote].org)

  useEffect(() => {
    setInterval(() => {
      const num = Math.floor(Math.random() * quotes.length)
      const quoteBlock = document.querySelector('.testimonial-card')
      setCurrentQuote(quotes[num])
      setQuoteText(quotes[num].text)
      setQuoteAuthor(quotes[num].author)
      setQuoteOrg(quotes[num].org)
      // quoteBlock.classList.add('home-testimonial-card-fadein')
    }, 12000)
  }, [])

  return (
    <section className="testimonial-wrapper">
      <div className="testimonial-card">
        <div className="testimonial-text-container">
          <p className="testimonial-text">{quoteText}</p>
          <div className="testimonial-person-wrapper">
            <p className="testimonial-name">- {quoteAuthor}</p>
            <p className="testimonial-org">{quoteOrg}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
